import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';


export const fetchAll = createAsyncThunk(
    'campaign/search',
    async (params) => {
        const response = await api.post("/api/campaign/search", params)
        return response.data;
    }
);

export const fetchRecipent = createAsyncThunk(
    'campaign/recipent/search',
    async (params) => {
        const response = await api.post("/api/campaign/recipients/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'campaign/fetchById',
    async (id) => {
        const response = await api.get(`/api/campaign/${id}`)
        return response.data;
    }
);

export const create = createAsyncThunk(
    'campaign/create',
    async ({ values }, { rejectWithValue }) =>
        await api.post(`/api/campaign`, values)
            .then((res) => res.data)
            .catch((err) => rejectWithValue(err.response))

);

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        items: null,
        loading: false,
        error: null,
        campaignItem: {},
        selectedItem: null,
        recipients : null,
        errors: [],
        pnrInfo: {}
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchRecipent.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                state.recipients = action.payload.recipients;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(create.fulfilled, (state, action) => {
                state.campaignItem = action.payload;
                console.log("action", action);
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
           
    },
});


export default campaignSlice.reducer;