// ** Redux Imports
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "./rootReducer";
import { templateApi } from "./templateApi";

const middlewares = [
  templateApi.middleware,
];
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {

    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares);
  },
});
setupListeners(store.dispatch)

export { store };
