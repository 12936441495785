import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import toast from "react-hot-toast";
export const fetchAll = createAsyncThunk(
    'whatsappRequests/fetchAll',
    async (params) => {
        const response = await api.post("/api/whatsapp/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'whatsappRequests/fetchById',
    async ({id}) => {
        const response = await api.get(`/api/whatsapp/${id}`)
        return response.data;
    }
);





const emailSlice = createSlice({
    name: 'whatsappRequests',
    initialState: {
        items: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {},
        moduleOpended: false,
    },
    reducers: {
        toggleModal: (state, action) => {
            state.moduleOpended = !state.moduleOpended;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                state.moduleOpended = true
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => {
                    console.log('action', action);
                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = true;
                    state[`${actionName}_error`] = null;
                    state[`${actionName}_errors`] = null;


                    // should be updated 
                    state.loading = true;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = false;
                    state[`${actionName}_error`] = null;
                    state[`${actionName}_errors`] = null;

                    const getActions = ['fetch', 'get', 'search', 'load'];
                    const containsAction = getActions.some(action => actionName.includes(action));
                    !containsAction && toast.success("Operation completed successfully");

                    // should be updated 
                    state.loading = false;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action, payload) => {

                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = false;
                    state[`${actionName}_error`] = action.error.message;
                    state[`${actionName}_errors`] = action.payload?.errors;


                    const failCallBack = action.meta?.arg?.failCallBack
                    // toast.error(action.payload?.data?.errorMessage ??
                    //     "Somthing went wronge please try again later...");
                    failCallBack && failCallBack(action.payload?.errors || {})

                    // should be updated 
                    state.loading = false;
                    state.error = action.error.message;
                    state.errors = action.payload?.errors;
                }
            )
            
    },
});

export const { toggleModal } = emailSlice.actions;
export default emailSlice.reducer;

