import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handlePagination } from "@src/redux/helpers";
import api from "@src/utility/api";
import toast from "react-hot-toast";

export const fetchAll = createAsyncThunk("client/fetchAll", async (params) => {
  const response = await api.post("/api/client/search", params);
  return response.data;
});

export const getAllSender = createAsyncThunk(
  "client/getAllSender",
  async (params) => {
    const response = await api.get("/api/senders", params);
    return response.data;
  }
);

export const fetchById = createAsyncThunk("client/fetchById", async (id) => {
  const response = await api.get(`/api/client/${id}`);
  return response.data;
});

export const openEditModal = createAsyncThunk(
  "client/openModal",
  async (payload) => {
    return payload;
  }
);

export const create = createAsyncThunk("client/create", async ({ values }) => {
  try {
    const response = await api.post(`/api/client`, values);
    return response.data;
  } catch (err) {
    throw err.response;
  }
});

export const update = createAsyncThunk(
  "client/update",
  async ({ values }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/client/update`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response); // Return rejected value
    }
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState: {
    items: [],
    loading: false,
    submitting: false,
    error: null,
    errors: null,
    selectedItem: {},
    openModal: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAll.fulfilled, (state, action) => {
        handlePagination(state, action.payload);
      })
      .addCase(fetchById.fulfilled, (state, action) => {
        state.selectedItem = action.payload;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
      })
      .addCase(getAllSender.fulfilled, (state, action) => {
        state.senders = action.payload;
      })
      .addCase(update.fulfilled, (state, action) => {
        const allSenders = JSON.parse(JSON.stringify(state.senders));
        const allItems = JSON.parse(JSON.stringify(state));

        const senderArray = action.payload?.senders?.map((senderId) => {
          const sender = allSenders.find((obj) => obj.id === senderId);
          return sender;
        });

        const updateItemIndex = allItems.items.findIndex(
          (x) => x.id === action.payload.id
        );
        if (updateItemIndex !== -1) {
          allItems.items[updateItemIndex].createdDate =
            action.payload.createdDate;
          allItems.items[updateItemIndex].isActive = action.payload.isActive;
          allItems.items[updateItemIndex].name = action.payload.name;
          allItems.items[updateItemIndex].senders = senderArray;
        }
        state.items = allItems.items;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
        toast.success("Client successfully updated!", {
          duration: 3000,
        });
        state.openModal = false;
      })
      .addCase(create.fulfilled, (state, action) => {
        const allSenders = JSON.parse(JSON.stringify(state.senders));
        const senderArray = action.payload?.senders?.map((senderId) => {
          const sender = allSenders.find((obj) => obj.id === senderId);
          return sender;
        });
        action.payload.senders = senderArray;
        action.payload.isActive = action.payload?.isDefault;

        toast.success("Client successfully created!", {
          duration: 3000,
        });

        state.items = [action.payload, ...state.items];

        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
        // state.openModal = false;
      })
      .addCase(openEditModal.fulfilled, (state, action) => {
        state.openModal = action.payload.IsOpen;
        state.item = action.payload.item;
        state.mode = action.payload.mode;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
      })
     
  },
});

export default clientSlice.reducer;
