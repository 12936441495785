import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';

export const fetchAll = createAsyncThunk(
    'emailRequests/fetchAll',
    async (params) => {
        const response = await api.post("/api/email/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'emailRequests/fetchById',
    async (id) => {
        const response = await api.get(`/api/email/${id}`)
        return response.data;
    }
);


export const create = createAsyncThunk(
    'emailRequests/create',
    async (payload) => {
        const response = await api.post(`/api/email`, payload)
        return response.data;
    }
);

export const download = createAsyncThunk(
    'emailRequests/download',
    async (id) => {
        const response = await api.get(`/api/email/${id}/document`)
        return response.data;
    }
);

const downloadContent = (htmlContent) => {
    if (!htmlContent) {
        htmlContent = "<H1>Content not found.</H1>"
    }
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Email content.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

const emailSlice = createSlice({
    name: 'emailRequests',
    initialState: {
        items: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {},
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(download.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                const { successCallBack } = action.meta.arg;
                downloadContent(action.payload.content);
                successCallBack && successCallBack(action.payload)
            })
            
            // .addCase(completeRequest.fulfilled, (state, action) => {
            //     const { successCallBack } = action.meta.arg;
            //     toast.success('Request submitted successfully');
            //     successCallBack && successCallBack(action.payload)
            // })
            // .addCase(completeRequest.rejected, (state, action) => {
            //     const { failCallBack } = action.meta.arg;
            //     toast.error(action.payload?.data?.errorMessage ?? "Somthing went wronge please try again later...");
            //     failCallBack && failCallBack(action.payload?.data||{})
            // })
           
    },
});


export default emailSlice.reducer;

