import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';



export const templateApi = createApi({
    reducerPath: 'templateApi',
    baseQuery: axiosBaseQuery(),
    endpoints(build) {
        return {
            getContentById: build.query({
                query: (id) => ({ url: `/api/template/${id}/content`, method: 'get' })
            }),
            parseTemplate:build.mutation({
                query: (template) => ({ url: `/api/template/parse`,data:template, method: 'post' })
            })
        };
    },
});

export const { useGetContentByIdQuery,useParseTemplateMutation } = templateApi