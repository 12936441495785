import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handlePagination } from "@src/redux/helpers";
import api from "@src/utility/api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const fetchAll = createAsyncThunk(
  "providers/fetchAll",
  async (params) => {
    const response = await api.post("/api/provider/search", params);
    return response.data;
  }
);

export const fetchById = createAsyncThunk("providers/fetchById", async (id) => {
  const response = await api.get(`/api/provider/${id}`);
  return response.data;
});

export const update = createAsyncThunk(
  "providers/update",
  async ({ values }, { rejectWithValue }) =>
    await axios
      .post(`/api/provider/update`, values)
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response))
);

export const create = createAsyncThunk(
  "providers/create",
  async ({ values }, { rejectWithValue }) =>
    await axios
      .post(`/provider`, values)
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response))
);
export const openEditModal = createAsyncThunk(
  "providers/openModal",
  async (payload) => {
    return payload;
  }
);

export const setDefaultProviderEvent = createAsyncThunk(
  "providers/setDefaultProviderEvent",
  async ({ values }, { rejectWithValue }) =>
    await axios
      .post(`/api/provider/setDefault`, values)
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response))
);

const providersSlice = createSlice({
  name: "providers",
  initialState: {
    items: [],
    loading: false,
    error: null,
    errors: null,
    selectedItem: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAll.fulfilled, (state, action) => {
        handlePagination(state, action.payload);
      })
      .addCase(fetchById.fulfilled, (state, action) => {
        state.selectedItem = action.payload;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
      })
      .addCase(create.fulfilled, (state, action) => {
        state.providerItem = action.payload;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
      })
      .addCase(update.fulfilled, (state, action) => {
        debugger;
        state.providerItem = action.payload;
        const allItems = JSON.parse(JSON.stringify(state.items));
        const updateItemIndex = allItems.findIndex(
          (x) => x.id === action.payload.id
        );
        if (updateItemIndex !== -1) {
          allItems[updateItemIndex].name = action.payload.name;
          allItems[updateItemIndex].baseUrl = action.payload?.baseURL;
          allItems[updateItemIndex].isDefault = action.payload?.isDefault;
          allItems[updateItemIndex].isEnableWebhook =
            action.payload?.isEnableWebhook;
          allItems[updateItemIndex].webhookURL =
            action.payload?.isEnableWebhook == true
              ? action.payload.webhookURL
              : "";
          allItems[updateItemIndex].isCheckManualStatus =
            action.payload?.isCheckManualStatus;
          allItems[updateItemIndex].checkMessageStatusTime =
            action.payload?.isCheckManualStatus == true
              ? action.payload?.checkMessageStatusTime
              : 0;
          allItems[updateItemIndex].isUndeliveredMessageRetry =
            action.payload?.isUndeliveredMessageRetry;
          allItems[updateItemIndex].undeliveredMessageRetiresCount =
            action.payload?.isUndeliveredMessageRetry == true
              ? action.payload?.undeliveredMessageRetiresCount
              : 0;
          allItems[updateItemIndex].channel = action.payload?.channel;
        }
        toast.success("Provider successfully updated!", {
          duration: 3000,
        });
        state.items = allItems;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
        state.openModal = false;
      })
      .addCase(openEditModal.fulfilled, (state, action) => {
        state.openModal = action.payload.IsOpen;
        state.Item = action.payload.Item;
        state.Mode = action.payload.Mode;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
      })
      .addCase(setDefaultProviderEvent.fulfilled, (state, action) => {
        state.Item = action.payload.Item;
        const { successCallBack } = action.meta.arg;
        successCallBack && successCallBack(action.payload);
      })

    
  },
});

export default providersSlice.reducer;
