import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import toast from 'react-hot-toast';

export const fetchAll = createAsyncThunk(
    'sender/fetchAll',
    async (params) => {
        const response = await api.post("/api/senders/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'sender/fetchById',
    async (id) => {
        const response = await api.get(`/api/senders/${id}`)
        return response.data;
    }
);


export const create = createAsyncThunk(
    'sender/create',
    async ({ values }, { rejectWithValue }) => {
        try {
            const response = await api.post(`/api/senders`, values);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const update = createAsyncThunk(
    'sender/update',
    async ({ values }, { rejectWithValue }) =>
        await api.put(`/api/senders/update`, values)
            .then((res) => res.data)
            .catch((err) => rejectWithValue(err.response))
);

export const openEditModal = createAsyncThunk(
    'sender/openModal',
    async (payload) =>{
        return payload;
    }
);



const senderSlice = createSlice({
    name: 'sender',
    initialState: {
        items: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {

        },
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(create.fulfilled, (state, action) => {
                state.senderItem = action.payload;
                toast.success('Sender successfully created!', {
                    duration: 3000,
                });
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload);
                state.openModal = false; 
            })
            .addCase(update.fulfilled, (state, action) => {
                state.senderItem = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(openEditModal.fulfilled, (state, action) => {
                state.openModal = action.payload.IsOpen;
                state.Item = action.payload.Item;
                state.Mode = action.payload.Mode;
                state.error = null;
                state.errors = null;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload);
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.errors = action.payload;
            })
         
    },
});


export default senderSlice.reducer;