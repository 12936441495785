import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';

export const fetchRecipent = createAsyncThunk(
    'campaign/recipent/search',
    async (params) => {
        const response = await api.post("/api/campaign/recipients/search", params)
        return response.data;
    }
);

const recipentSlice = createSlice({
    name: 'recipent',
    initialState: {
        items: null,
        loading: false,
        error: null,
        campaignItem: {},
        selectedItem: null,
        errors: [],
        pnrInfo: {}
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            
            .addCase(fetchRecipent.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
           
    },
});


export default recipentSlice.reducer;