import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@src/utility/api';

export const fetchAll = createAsyncThunk(
  'channel/fetchAll',
  async () => {
      const response = await api.get("/api/channel");
      return response.data;
  }
);



const channelSlice = createSlice({
  name: 'channel',
  initialState: {
    channelOptions: [],
    // other slice state if needed
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.channelOptions = action.payload;
    });
  },
});

export default channelSlice.reducer;