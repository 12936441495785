import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import toast from 'react-hot-toast'

export const fetchAll = createAsyncThunk(
    'smsRequests/fetchAll',
    async (params) => {
        const response = await api.post("/api/sms/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'smsRequests/fetchById',
    async (id) => {
        const response = await api.get(`/api/sms/${id}`)
        return response.data;
    }
);


export const create = createAsyncThunk(
    'smsRequests/create',
    async (payload) => {
        const response = await api.post(`/api/sms`, payload)
        return response.data;
    }
);


const smsSlice = createSlice({
    name: 'smsRequests',
    initialState: {
        items: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {
            // refundRequest: { histories: [] },
            // payments: [],
            // bookingDetails: {}
        },
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            // .addCase(completeRequest.fulfilled, (state, action) => {
            //     const { successCallBack } = action.meta.arg;
            //     toast.success('Request submitted successfully');
            //     successCallBack && successCallBack(action.payload)
            // })
            // .addCase(completeRequest.rejected, (state, action) => {
            //     const { failCallBack } = action.meta.arg;
            //     toast.error(action.payload?.data?.errorMessage ?? "Somthing went wronge please try again later...");
            //     failCallBack && failCallBack(action.payload?.data||{})
            // })
           
    },
});


export default smsSlice.reducer;

