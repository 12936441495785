// ** React Imports
import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context

import { themeConfig,ThemeContext,Spinner } from "@app/layout";

// ** ThemeConfig

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "./index.scss"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
// // ** Core styles

// ** Core styles

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));


function RootApp({props}) {
  console.log("refund application started",props)
  return (
    <BrowserRouter >
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <LazyApp />
          </ThemeContext>
        </Suspense>
      </Provider>
    </BrowserRouter>
  )
}

export default RootApp
