import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@src/utility/api';

// import api from "axios";
export const getLookups = createAsyncThunk(
    'lookups/getLookups',
    async (params) => {
        const response = await api.get("/api/lookups", { params })
        return response.data;
    }
);



const invoicesSlice = createSlice({
    name: 'lookups',
    initialState: {

        providers: [],
        clients: [],
        senders: [],
        messageStatus: [],


        loading: false,
        error: null,
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLookups.fulfilled, (state, { payload }) => {
                state.providers = payload.providers;
                state.clients = payload.clients;
                state.senders = payload.senders;
                state.messageStatus = payload.messageStatus;
            })

            // .addMatcher(
            //     (action) => action.type.endsWith('/pending'),
            //     (state) => {
            //         state.loading = true;
            //         state.error = null;
            //     }
            // )
            // .addMatcher(
            //     (action) => action.type.endsWith('/fulfilled'),
            //     (state, action) => {
            //         state.loading = false;
            //         state.error = null;
            //     }
            // )
            // .addMatcher(
            //     (action) => action.type.endsWith('/rejected'),
            //     (state, action) => {
            //         state.loading = false;
            //         state.error = action.error.message;
            //     }
            // )

    },
});


export default invoicesSlice.reducer;

